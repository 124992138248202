import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import pin from "./icons/pin.svg";
import mail from "./icons/mail.svg";
import clock from "./icons/clock.svg";
import cellphone from "./icons/cellphone.svg";

const Footer = () => (
  <footer className="footer">
    <nav className="information">
      <div className="information_items">
        <img src={pin} alt="pin" width="36px" height="36px" />
        <p>м. Київ, Вулиця Семена Скляренка 17</p>
      </div>

      <div className="information_items">
        <img src={mail} alt="mail" width="36px" height="36px" />
        <p>nato-uniform@ukr.net</p>
      </div>
      <div className="information_items">
        <img src={clock} alt="clock" width="36px" height="36px" />
        <ul>
          <li>Вт-Пт: з 9:00 до 18:00</li>
          <li>Сб-Нд: з 9:00 до 17:00</li>
          <li>Пн: вихідний</li>
        </ul>
      </div>
      <div className="information_items">
        <img src={cellphone} alt="cellphone" width="36px" height="36px" />
        <ul>
          <li>+38 (066) 365-77-81</li>
          <li>+38 (096) 897-33-07</li>
        </ul>
      </div>
    </nav>
    <div className="icons">
      <Link to="/" className="icon instagram"></Link>
      <Link to="/" className="icon facebook"></Link>
      <Link to="/" className="icon twitter"></Link>
      <Link to="/" className="icon youtube"></Link>
      <Link to="/" className="icon whatsapp"></Link>
    </div>
  </footer>
);

export default Footer;
